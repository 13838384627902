<template>
  <el-dialog
    id="SKUInfoDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="596px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @open="onBeforeShow"
    @opened="onShow"
    @close="onBeforeClose"
    destroy-on-close
    append-to-body
    @keydown.enter.native="onCommit"
  >
    <el-form ref="SKUInfoForm" :model="info" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="!info" justify="center" align="middle" type="flex" style="height:500px">
        <img class="svg" :src="require('../../assets/icons/no_data.svg')" />
      </el-row>
      <el-row v-else>
        <el-form-item :label="$t('Назва')">
          <el-input v-model="info.sku" autocomplete="off" readonly></el-input>
        </el-form-item>
        <el-form-item :label="$t('Бренд')">
          <el-input v-model="info.brand" autocomplete="off" readonly></el-input>
        </el-form-item>
        <el-form-item :label="$t('Маркетуюча організація')">
          <el-input v-model="info.marketOrg" autocomplete="off" readonly></el-input>
        </el-form-item>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import Icon from "@/components/IconPack";

export default {
  name: "SKUInfo",
  components: { Icon },
  props: ["busEvent"],
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: $tt("Це поле є необхідним") };
    return {
      loading: true,
      dialogShow: false,
      title: $tt("Інформація про товар"),
      operation: null,
      model: {},
      initModel: {},
      rules: {},
      noInfo: true,
    };
  },

  methods: {
    onCommit() {
      this.dialogShow = false;
      this.$store.dispatch("sku/set_info", null);
    },
    onBeforeShow() {
      this.loading = true;
    },
    onShow() {
      this.$refs.SKUInfoForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.loadSKUInfo();
    },
    onBeforeClose() {
      this.$store.dispatch("sku/reset_info");
    },
    loadSKUInfo() {
      this.loading = true;
      this.$store
        .dispatch("sku/get_info", {
          drugsId: this.model.drugsId,
          outputLang: this.outputLang,
        })
        .catch((err) => {
          this.$message.error(parseError(err.data.msg));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.title = (data && data.title) || this.title;
      this.initModel = _.extend({}, data ? data.initModel : {});
      this;
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    outputLang() {
      return this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang;
    },
    outputLangLong() {
      return { ua: "ukr", en: "eng", ru: "rus" }[this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang];
    },
    info() {
      var infoObj = this.$store.getters["sku/getInfo"];
      if (infoObj) {
        return {
          sku: infoObj.sku,
          brand: infoObj.fields ? infoObj.fields["name_" + this.outputLangLong] : "-",
          marketOrg: infoObj.fields ? infoObj.fields["market_name_" + this.outputLangLong] : "-",
        };
      } else return null;
    },
  },
};
</script>
<style lang="scss">
#SKUInfoDialog {
  .el-dialog {
    max-height: 640px !important;
  }
  .button-in-form {
    margin-bottom: 16px;
  }
}
</style>
